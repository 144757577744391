import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class UserAnalyticsServiceApi {
    Order(user_id,type) {
          return this.initiateAPICall({ 
            action:"orders",
            user_id:user_id,
            type:type
          })
      }
     Trades(user_id,type) {
          return this.initiateAPICall({ 
            action:"trades",
            user_id:user_id,
            type:type
          })
      }
      UserAnalytics(user_id) {
        return this.initiateAPICall({ 
          action:"total",
          user_id:user_id,
        })
    }

  
      initiateAPICall(params){
        return axios
          .get(API_URL + 'admin/analytics', {
            headers:  authHeader(),
            params:params,
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
}



export default new UserAnalyticsServiceApi()


