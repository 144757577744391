<script>
import UserAnalyticsServiceApi from "@/service/api/user-analytics"
export default {
  props:["userID"],
  data() {
    return {
      statData: [
        {
          title: "Total Sales",
          icon: "fas fa-coins",
          value: "0",
        },
        {
          title: "Total Spent",
          icon: "fas fa-shopping-cart",
          value: "AED 0",
        },
        {
          title: "Total Earn from Sales",
          icon: "ri-money-dollar-circle-line",
          value: "AED 0",
        },
      ],
    };
  },
  methods:{
    loadUserAnalysis(){
        UserAnalyticsServiceApi.UserAnalytics(this.userID).then((response)=>{
          if(response.data){
            console.log(response.data)
            this.statData[0].value = response.data.user_total_sales;
            this.statData[1].value = "AED "+response.data.user_total_spent;
            this.statData[2].value = "AED "+response.data.total_profit;
          }

        })
    }
  },
  mounted(){
      this.loadUserAnalysis();
  }

};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h5 class="mb-0">{{ data.value }}</h5>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
