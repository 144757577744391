<script>
import UserAnalyticsServiceApi from "@/service/api/user-analytics";
import Progress from "@/service/transactionType";
export default {
  props:["userID","type"],
  data() {
    return {
      tradeData: [],
      transaction: Progress.buy,
      loading: false,
    };
  },
  methods: {
    loadOrders() {
      this.loading = true;
      UserAnalyticsServiceApi.Order(this.userID,this.type)
        .then((respose) => {
          this.tradeData = respose.data.orders;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadOrders();
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <button
        @click="loadOrders"
        class="btn btn-sm btn-secondary float-right"
      >
        <i class="ri-refresh-line"></i>
      </button>
      <h4 class="card-title mb-4">{{this.type ==="pending"?"Pending Order":"Order History"}}</h4>
      <div v-if="loading">
        <b-spinner
          style="display:block;margin:auto;font-size:18px;margin-top:48px;margin-bottom:48px"
          variant="danger"
        ></b-spinner>
      </div>

      <div
        data-simplebar
        style="max-height: 330px;"
        v-if="!loading && tradeData.length"
      >
        <ul class="list-unstyled activity-wid">
          <li
            class="activity-list"
            v-for="(data, index) in tradeData"
            :key="index"
          >
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-white text-primary rounded-circle">
                <img
                  :src="data.size.product.image"
                  alt=""
                  srcset=""
                  height="26"
                />
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{
                    moment(data.updated_at).format("MMMM Do YYYY, h:mm:ss a")
                  }}
                </h5>
              </div>

              <div>
                <p class="text-muted mb-0">{{ data.size.product.name }}</p>
              </div>
              <div :style="`color:${transaction[data.progress].color}`">
                {{ transaction[data.progress].label }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="!loading && tradeData.length < 1"
        style="font-size:18px;text-align:center;margin:48px"
      >
        No Orders
      </div>
    </div>
  </div>
</template>
