<script>
import Widget from "@/components/user/widget";
import Order from "@/components/user/order";
import Trade from "@/components/user/trade";


export default {
  props: ["userData"],
  components: {
    Widget,
    Order,
    Trade, 
  },
};
</script>

<template>
  <div v-if="userData">
    <Widget :userID="userData.id" />
    <div class="row">
      <div class="col-md-6">
        <Order type="pending" :userID="userData.id" />
      </div>
      <div class="col-md-6">
        <Trade type="pending"  :userID="userData.id" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <Order type="history" :userID="userData.id" />
      </div>
      <div class="col-md-6">
        <Trade type="history" :userID="userData.id" />
      </div>
    </div>
  </div>
</template>
